<template>
        <b-col md="12" sm="12" lg="12">
            <p></p>
        </b-col>
</template>
<script>
export default {
    props: ['data'],
    data () {
        return {
        }
    }
}
</script>
