<template>
  <div>
    <b-row>
      <b-col md="12" sm="12" lg="12">
        <ValidationProvider :name="data.label" :vid="data.field_name" :rules="data.isRequired ? `required` : ``">
          <b-form-group
            label-cols-sm="2"
            :label-for="data.field_name"
            slot-scope="{ valid, errors }"
          >
            <template v-slot:label>
              {{ local === 'bn' ? data.label_bn : data.label }} <span class="text-danger" v-if="data.isRequired === true">*</span>
              <span v-if="data.help_text !== ''" class="ml-2" style="border:1px solid #66cc99;border-radius:50%;cursor:pointer" data-toggle="tooltip" data-placement="top" :title="data.help_text">?</span>
            </template>
            <v-select
                class="lrcpn-dd"
                id="field_name"
                v-model="data.value"
                :reduce="op => op.value"
                multiple
                :options="agricultureProductTypeList"
                label="text"
                :state="errors[0] ? false : (valid ? true : null)"
                @input="changeProductInfo()"
            >
            </v-select>
            <div class="invalid-feedback d-block">
                {{ errors[0] }}
            </div>
          </b-form-group>
        </ValidationProvider>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" sm="12" lg="12">
        <ValidationProvider :name="productList.label" :vid="productList.field_name" :rules="productList.isRequired ? `required` : ``">
          <b-form-group
            label-cols-sm="2"
            :label-for="productList.field_name"
            slot-scope="{ valid, errors }"
          >
            <template v-slot:label>
              {{ local === 'bn' ? productList.label_bn : productList.label }} <span class="text-danger" v-if="productList.isRequired === true">*</span>
              <span v-if="productList.help_text !== ''" class="ml-2" style="border:1px solid #66cc99;border-radius:50%;cursor:pointer" data-toggle="tooltip" data-placement="top" :title="productList.help_text">?</span>
            </template>
            <v-select
                class="lrcpn-dd"
                id="field_name"
                v-model="productList.value"
                :reduce="op => op.value"
                multiple
                :options="data.value ? agricultureProductListData(): []"
                label="text"
                :state="errors[0] ? false : (valid ? true : null)"
            >
            </v-select>
            <div class="invalid-feedback d-block">
                {{ errors[0] }}
            </div>
          </b-form-group>
        </ValidationProvider>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { ValidationProvider } from 'vee-validate'
export default {
    props: ['data', 'allData', 'productList'],
    components: {
        ValidationProvider
    },
    data () {
      return {
        drpList: []
      }
    },
    computed: {
      local: function () {
        return this.$i18n.locale
      },
      agricultureProductTypeList: function () {
        return this.$store.state.ExternalLrcpn.lrcpnObj.agricultureProductTypeList
      }
    },
    watch: {
      local: function (newVal, oldVal) {
        if (newVal !== oldVal) {
        }
      },
      'productList.value': function (newVal, oldVal) {
        const dataFind = this.allData.find(dt => dt.field_name === 'product_de_4058')
        const indexOfItem = this.allData.indexOf(dataFind)
        this.allData[indexOfItem] = this.productList
      }
    },
    created () {
      if (this.data.value) {
        const datasVal = this.data.value
        this.data.value = JSON.parse(this.data.value)
        if (!Array.isArray(this.data.value)) {
          this.data.value = datasVal
        }
      }
      if (this.productList.value) {
        const datasVasl = this.productList.value
        this.productList.value = JSON.parse(this.productList.value)
        if (!Array.isArray(this.productList.value)) {
          this.productList.value = datasVasl
        }
      }
      this.changeProductInfo()
    },
    methods: {
      agricultureProductListData () {
        const productList = []
        this.data.value.forEach((item, key) => {
          const agricultureProductLists = this.$store.state.ExternalLrcpn.lrcpnObj.agricultureProductList.filter(item1 => item1.product_type_id === item)
          agricultureProductLists.forEach((item1, key) => {
            productList.push(item1)
          })
        })
        this.agricultureProductList = productList
        return productList
      },
      changeProductInfo () {
        const productList = []
        if (this.data.value) {
          if (this.data.value.length > 0) {
            this.data.value.forEach((item, key) => {
              const agricultureProductLists = this.$store.state.ExternalLrcpn.lrcpnObj.agricultureProductList.filter(item1 => item1.product_type_id === item)
              agricultureProductLists.forEach((item1, key) => {
                productList.push(item1)
              })
            })
            this.agricultureProductList = productList
            const searchData = []
            if (this.agricultureProductList.length > 0) {
              this.agricultureProductList.forEach((item, key) => {
                this.productList.value.map((item1) => {
                  if (item1 === item.value) {
                    searchData.push(item1)
                  }
                })
              })
            }
            this.productList.value = searchData
          } else {
            // this.agricultureProductList = []
            this.productList.value = []
          }
        }
      }
    }
}
</script>
